import React from 'react'
import tw from 'twin.macro'

const PlusPage = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8 3.6001C9.84517 3.6001 8.9295 3.97938 8.25437 4.65451C7.57924 5.32964 7.19995 6.24532 7.19995 7.2001V28.8001C7.19995 29.7549 7.57924 30.6705 8.25437 31.3457C8.9295 32.0208 9.84517 32.4001 10.8 32.4001H25.2C26.1547 32.4001 27.0704 32.0208 27.7455 31.3457C28.4207 30.6705 28.8 29.7549 28.8 28.8001V13.3453C28.7997 12.3906 28.4203 11.4751 27.7452 10.8001L21.6 4.6549C20.925 3.97972 20.0095 3.6003 19.0548 3.6001H10.8ZM19.8 14.4001C19.8 13.9227 19.6103 13.4649 19.2727 13.1273C18.9352 12.7897 18.4773 12.6001 18 12.6001C17.5226 12.6001 17.0647 12.7897 16.7272 13.1273C16.3896 13.4649 16.2 13.9227 16.2 14.4001V18.0001H12.6C12.1226 18.0001 11.6647 18.1897 11.3272 18.5273C10.9896 18.8649 10.8 19.3227 10.8 19.8001C10.8 20.2775 10.9896 20.7353 11.3272 21.0729C11.6647 21.4105 12.1226 21.6001 12.6 21.6001H16.2V25.2001C16.2 25.6775 16.3896 26.1353 16.7272 26.4729C17.0647 26.8105 17.5226 27.0001 18 27.0001C18.4773 27.0001 18.9352 26.8105 19.2727 26.4729C19.6103 26.1353 19.8 25.6775 19.8 25.2001V21.6001H23.4C23.8773 21.6001 24.3352 21.4105 24.6727 21.0729C25.0103 20.7353 25.2 20.2775 25.2 19.8001C25.2 19.3227 25.0103 18.8649 24.6727 18.5273C24.3352 18.1897 23.8773 18.0001 23.4 18.0001H19.8V14.4001Z"
            fill="currentColor"
        />
    </svg>
)

export default PlusPage
