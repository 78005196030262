import React from 'react'

const NumberOne = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#EEEEFD" />
        <path d="M24.4 32H27.5V17.1H21.5V19.72H24.4V32Z" fill="#5950EC" />
    </svg>
)

export default NumberOne
