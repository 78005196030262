import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { RegisterButton } from '../../UI'
import Word from '../../../images/icons/word.svg'
import Excel from '../../../images/icons/streamline/excel.svg'

const style = {
    wrapper: tw`max-w-7xl my-8 mx-auto px-4 sm:px-6 lg:px-8 `,
    announcer: tw`text-base font-normal mb-0 text-tellow-purple`,
    bigTitle: tw`text-3xl font-bold items-start`,
    subtitle: tw`text-xs  items-start mb-0 justify-center`,
    smalltitle: tw`text-xxs font-bold items-start mb-0 justify-center`,
    img: tw`md:block mx-auto mb-0 transform translate-x-5 shadow-xl rounded-md`,
    buttons: tw`h-5 w-5 md:block mx-auto mb-0 transform translate-x-5`,
    imageContainer: tw`flex flex-col justify-center m-8 md:flex-row gap-6`,
    imageContainerBottom: tw`flex flex-col justify-end mb-0 px-3`,
    link: tw`antialiased text-xs text-tellow-purple cursor-pointer font-semibold mt-6 mr-6`,
}

const DownloadButtons = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;
`

const Content = styled.div`
    background-color: ${(props) => props.theme.color.tellowGray200};
    display: flex;
    border-radius: 10px;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
`

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @media only screen and (max-width: 768px) {
        padding-bottom: 1rem;
    }
`

const Line = styled.span`
    border-bottom: 1px solid gray;
    opacity: 30%;
    display: flex;
    height: 2px;
    transform: translateY(1.75rem);
`

const Button = styled.div`
    display: flex;
    margin-left: 0.5rem;
    align-items: center;
    justify-content: center;
`

const Icon = styled.a`
    border-radius: 999px;
    background-color: ${(props) => props.theme.color.tellowWhite};
    padding: 0.5rem;

    margin-left: 0.5rem;

    &:hover {
        cursor: pointer;
        box-shadow: 5px 5px 5px ${(props) => transparentize(0.9, props.theme.color.tellowPurple)}, -5px 5px 5px ${(props) => transparentize(0.9, props.theme.color.tellowPurple)};
        transition: 0.3s;

        img {
            transform: scale(0.75);
            transition: 0.3s ease-in-out;
        }
    }

    @media only screen and (max-width: 768px) {
        height: 2.25rem;
        width: 2.25rem;
    }
`

const ThreeImages = ({ data, bottomImage, hasRegisterButton, divider }) => {
    const navigateToInvoices = (url) => {
        window.open(url)
        window.location.href = 'https://www.tellow.nl/functies/facturen/'
    }

    return (
        <div css={[style.wrapper, bottomImage ? style.imageContainerBottom : style.imageContainer]}>
            <h1 css={style.bigTitle}>{data.title}</h1>
            <p css={style.announcer}>{data.text}</p>

            <div css={style.imageContainer}>
                {data.templates.map((template) => (
                    <CenteredContent key={template.subtitle}>
                        <p css={style.subtitle}>{template.title}</p>
                        <img css={style.img} src={template.image} alt={template.title} />
                        <Content>
                            <DownloadButtons>
                                <p css={style.smalltitle}>Gratis sjabloon</p>
                                <p css={style.subtitle}>Download</p>
                            </DownloadButtons>
                            <Button>
                                <Icon onClick={() => navigateToInvoices(template.wordUrl)}>
                                    <img css={style.buttons} src={Word} alt={data.altImage} />
                                </Icon>
                                <Icon onClick={() => navigateToInvoices(template.excelUrl)}>
                                    <img css={style.buttons} src={Excel} alt={data.altImage} />
                                </Icon>
                            </Button>
                        </Content>
                    </CenteredContent>
                ))}
            </div>
            {hasRegisterButton && (
                <CenteredContent>
                    <RegisterButton medium>Gratis factuurmaker</RegisterButton>
                </CenteredContent>
            )}
            {divider && <Line />}
        </div>
    )
}

export default ThreeImages
