import React from 'react'

const NumberTwo = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#EEEEFD" />
        <path
            d="M18.84 32H28.94V29.4H22.78L26.2 25.88C27.0933 24.9733 27.7467 24.1467 28.16 23.4C28.5867 22.64 28.8 21.8533 28.8 21.04C28.8 20.2 28.5933 19.4667 28.18 18.84C27.7667 18.2133 27.1867 17.7267 26.44 17.38C25.6933 17.0333 24.8267 16.86 23.84 16.86C23.0533 16.86 22.3 17.02 21.58 17.34C20.86 17.6467 20.2467 18.0867 19.74 18.66C19.2333 19.2333 18.9 19.9067 18.74 20.68L21.44 21.56C21.64 20.92 21.9533 20.4467 22.38 20.14C22.8067 19.82 23.2933 19.66 23.84 19.66C24.3733 19.66 24.8133 19.82 25.16 20.14C25.52 20.4467 25.7 20.8733 25.7 21.42C25.7 21.7267 25.62 22.04 25.46 22.36C25.3133 22.68 25.0333 23.08 24.62 23.56C24.22 24.0267 23.6267 24.6667 22.84 25.48L18.84 29.6V32Z"
            fill="#5950EC"
        />
    </svg>
)

export default NumberTwo
