/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/landingPages/header'
import TopHighlights from '../components/shared/landingPages/topHighlights'
import SideList from '../components/shared/landingPages/sideList'
import ThreeImages from '../components/shared/landingPages/threeImages'
import InvoiceOne from '../images/invoicelp/invoiceTemplates/TemplateBlue.png'
import InvoiceTwo from '../images/invoicelp/invoiceTemplates/TemplateRed.png'
import InvoiceThree from '../images/invoicelp/invoiceTemplates/TemplateGreen.png'
import Book from '../images/invoicelp/book'
import PlusPage from '../images/invoicelp/plusPage'
import Mobile from '../images/invoicelp/mobilePhone'
import Earth from '../images/invoicelp/earth'
import Mac from '../images/invoicelp/macbookpro.png'
import NumberOne from '../images/invoicelp/numberOne'
import NumberTwo from '../images/invoicelp/numberTwo'
import NumberThree from '../images/invoicelp/numberThree'

const topHighlightData = {
    title: `Gratis factureren met Tellow`,
    subtitle: `De voordelen van facturen maken en sturen met Tellow`,
    points: [
        {
            Icon: Book,
            title: `Handig adresboek`,
            body: `
            Sla de gegevens van je contacten op in het adresboek en valideer KvK-nummers in de KvK-database.
        `,
        },
        {
            Icon: Mobile,
            title: `On the go            `,
            body: `
            Met de handige app van Tellow kan je facturen maken en versturen waar en wanneer je maar wilt. 
        `,
        },
        {
            Icon: Earth,
            title: `Engelstalige facturen            `,
            body: `
            Stel gemakkelijk Engelse facturen op die je kunt versturen naar niet-Nederlandstalige klanten.
        `,
        },
        {
            Icon: PlusPage,
            title: `Documenten toevoegen            `,
            body: `
            Voeg eenvoudig de algemene voorwaarden of andere specificaties toe als bijlage. Zo heeft je klant alle benodigde informatie bij de hand.
        `,
        },
    ],
}

const headerData = {
    title: `Gratis factuur voorbeelden`,
    text: `Download jouw factuur templates in Word en Excel. Vul je gegevens in, exporteer naar pdf en stuur ze naar je klant. Makkelijk factureren kan met Tellow.`,
    textCTA: `Maak gratis facturen`,
    headerImage: Mac,
}

const sideListData = {
    head: `Download je gratis factuur voorbeeld en probeer het uit`,
    paragraph: `Als startende of ervaren ondernemer is factureren erg belangrijk. Zo verdien je namelijk je geld. Bij Tellow begrijpen wij dit en helpen je hiermee graag verder. Daarom bieden wij, volledig gratis, factuur voorbeelden aan. Je kunt deze downloaden en gebruiken als template in Word of Excel en exporteren naar pdf. Maar je kunt ook direct gratis via Tellow jouw facturen versturen.`,
    points: [
        {
            Icon: NumberOne,
            title: `Zoek een voorbeeld factuur die bij je past`,
            body: `
            Gebruik het template die het beste bij jouw bedrijf past. Bijvoorbeeld een factuur met btw of een factuur vrijgesteld van btw.
        `,
        },
        {
            Icon: NumberTwo,
            title: `Download je voorbeeld en gebruik als template`,
            body: `
            Wij hebben voorbeelden die je kan gebruiken in Excel of Word. Wel zo makkelijk!`,
        },
        {
            Icon: NumberThree,
            title: `Export je aangepaste template vanuit Word of Excel naar pdf`,
            body: `
            Verstuur je factuur als pdf naar je klant.
        `,
        },
    ],
}

const threeImagesDataWithVat = {
    title: `Gratis factuur voorbeelden met btw`,
    text: `Download je gratis voorbeeld factuur hieronder`,
    altImage: `Word and Excel Image`,

    templates: [
        {
            image: InvoiceOne,
            title: `Factuur template paars`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Paars-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Paars-btw-factuur.xlsx',
        },
        {
            image: InvoiceTwo,
            title: `Factuur template rood`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Rood-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Rood-btw-factuur.xlsx',
        },
        {
            image: InvoiceThree,
            title: `Factuur template groen`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Groen-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Groen-btw-factuur.xlsx',
        },
    ],
}

const threeImagesData = {
    title: `Gratis factuur voorbeelden zonder btw`,
    text: `Download je gratis voorbeeld factuur hieronder`,
    altImage: `Word and Excel Image`,

    templates: [
        {
            image: InvoiceOne,
            title: `Factuur template paars`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Paars-geen-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Paars-geen-btw-factuur.xlsx',
        },
        {
            image: InvoiceTwo,
            title: `Factuur template rood`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Rood-geen-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Rood-geen-btw-factuur.xlsx',
        },
        {
            image: InvoiceThree,
            title: `Factuur template groen`,
            wordUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Groen-geen-btw-factuur.docx',
            excelUrl: 'https://storage.googleapis.com/tellow-static/freeTemplates/Groen-geen-btw-factuur.xlsx',
        },
    ],
}

const GratisFactuursjabloon = () => (
    <Layout>
        <Meta
            name="robots"
            path="/gratis-factuur-voorbeeld"
            title="Gratis factuur voorbeelden | Tellow"
            description="Download hier je gratis factuur template voor zzp en eenmanszaak. Beschikbaar in Word en Excel. Probeer nu."
        />
        {/* First Component */}
        <Header data={headerData} purpleBackground columnSize bottomImage />

        {/* Second Component */}
        <ColoredBackgroundWithProps>
            <RelativeContainer>
                <ComponentContainer>
                    <SideList data={sideListData} alternate threeCol />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Third Component */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <ThreeImages data={threeImagesDataWithVat} bottomImage hasRegisterButton divider withVat />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Fourth Component */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <ThreeImages data={threeImagesData} hasRegisterButton bottomImage />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Fifth Component */}
        <ColoredBackgroundWithProps>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} hasRegisterButton textPurple />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    </Layout>
)

export default GratisFactuursjabloon
