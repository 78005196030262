import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

const style = {
    wrapper: tw`max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 py-4`,
    text: tw`text-base font-normal max-w-prose mb-12`,
    subtitle: tw`text-2xl font-semibold items-start`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6`,
    threeGrid: tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6`,
    alterateGrid: tw`grid grid-cols-1 md:grid-cols-2 gap-6`,
    marginText: tw`w-min-content  opacity-70`,
    buttons: tw`h-10 w-20`,
    svg: tw`h-10 w-10 fill-none! text-tellow-purple  rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
    altSvg: tw`h-14 w-14 fill-none! text-tellow-purple rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
    img: tw`md:block mx-auto mb-0 transform translate-x-5 `,
}

const Paragraph = styled.p`
    max-width: min-content;
    min-width: 20ch;
`

const Center = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: min-content;
    margin: 0 auto;
`

const LittleTitle = styled.p`
    color: ${(props) => props.theme.color.tellowPurple};
    font-weight: 500;
    align-self: flex-start;
`

const Line = styled.span`
    height: 2px;
    display: block;
    transform: translateY(1.75rem);

    background-image: linear-gradient(to right, ${({ theme }) => theme.color.tellowGray500} 50%, white 0%);
    background-position: bottom;
    background-size: 20px 2px;
    background-repeat: repeat-x;

    @media (max-width: 768px) {
        display: none;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(to right, white 0%, transparent 20%, transparent 80%, white 100%);
    }
`

const Back = styled.div`
    background-color: white;
    border-radius: 50%;
    z-index: 10;
    margin-bottom: 0.75rem;
`

function TopHighlights({ data, alternate, threeCol }) {
    let Cards = null

    /**
     * TODO: Refactor this.
     */
    if (alternate) {
        Cards = data.points.map(({ Icon, title, body, mainImage }) => (
            <Center key={title}>
                <Back>
                    <div css={style.altSvg}>
                        <Icon />
                    </div>
                </Back>
                <LittleTitle>{title}</LittleTitle>
                <Paragraph>{body}</Paragraph>
                {mainImage && <img css={style.buttons} src={mainImage} alt={data.altImage} />}
            </Center>
        ))
    }

    return (
        <div css={style.wrapper}>
            <h1 css={style.subtitle}>{data.head}</h1>
            <p css={style.text}>{data.paragraph}</p>
            <Line />
            {!threeCol && <div css={alternate ? style.alterateGrid : style.grid}>{Cards}</div>}
            {threeCol && <div css={threeCol ? style.threeGrid : style.grid}>{Cards}</div>}
        </div>
    )
}

export default TopHighlights
