import React from 'react'
import tw from 'twin.macro'

const Book = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2 8.64741C14.2375 7.6919 12.0827 7.19693 9.89997 7.20021C7.64097 7.20021 5.50258 7.72221 3.59998 8.64741V26.6474C5.56251 25.692 7.71722 25.197 9.89997 25.2002C12.9042 25.2002 15.6924 26.1182 18 27.6932C20.3869 26.0646 23.2104 25.1956 26.1 25.2002C28.359 25.2002 30.4974 25.7222 32.4 26.6474V8.64741C30.4375 7.6919 28.2827 7.19693 26.1 7.20021C23.841 7.20021 21.7026 7.72221 19.8 8.64741V21.6002C19.8 22.0776 19.6103 22.5354 19.2728 22.873C18.9352 23.2106 18.4774 23.4002 18 23.4002C17.5226 23.4002 17.0647 23.2106 16.7272 22.873C16.3896 22.5354 16.2 22.0776 16.2 21.6002V8.64741Z"
            fill="currentColor"
        />
    </svg>
)

export default Book
