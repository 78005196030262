import React from 'react'

const NumberThree = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#EEEEFD" />
        <path
            d="M23.217 32.24C24.2703 32.24 25.2103 32.02 26.037 31.58C26.8636 31.1267 27.517 30.5067 27.997 29.72C28.477 28.9333 28.717 28.0467 28.717 27.06C28.717 25.9133 28.4036 24.96 27.777 24.2C27.1503 23.44 26.3036 22.9667 25.237 22.78L28.117 19.5V17.1H18.817V19.7H24.637L20.817 23.86L21.537 25.36C22.0436 25.1067 22.6036 24.98 23.217 24.98C23.9636 24.98 24.5503 25.18 24.977 25.58C25.4036 25.9667 25.617 26.5 25.617 27.18C25.617 27.8733 25.397 28.4267 24.957 28.84C24.5303 29.24 23.9503 29.44 23.217 29.44C22.617 29.44 22.097 29.28 21.657 28.96C21.217 28.6267 20.8836 28.14 20.657 27.5L17.917 28.42C18.0903 29.1933 18.437 29.8667 18.957 30.44C19.477 31.0133 20.1103 31.46 20.857 31.78C21.6036 32.0867 22.3903 32.24 23.217 32.24Z"
            fill="#5950EC"
        />
    </svg>
)

export default NumberThree
