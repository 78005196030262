import React from 'react'
import tw from 'twin.macro'

const MobilePhone = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6 3.59998C11.6452 3.59998 10.7295 3.97926 10.0544 4.65439C9.37928 5.32952 9 6.2452 9 7.19998V28.8C9 29.7548 9.37928 30.6704 10.0544 31.3456C10.7295 32.0207 11.6452 32.4 12.6 32.4H23.4C24.3548 32.4 25.2705 32.0207 25.9456 31.3456C26.6207 30.6704 27 29.7548 27 28.8V7.19998C27 6.2452 26.6207 5.32952 25.9456 4.65439C25.2705 3.97926 24.3548 3.59998 23.4 3.59998H12.6ZM18 28.8C18.4774 28.8 18.9352 28.6103 19.2728 28.2728C19.6104 27.9352 19.8 27.4774 19.8 27C19.8 26.5226 19.6104 26.0647 19.2728 25.7272C18.9352 25.3896 18.4774 25.2 18 25.2C17.5226 25.2 17.0648 25.3896 16.7272 25.7272C16.3896 26.0647 16.2 26.5226 16.2 27C16.2 27.4774 16.3896 27.9352 16.7272 28.2728C17.0648 28.6103 17.5226 28.8 18 28.8Z"
            fill="currentColor"
        />
    </svg>
)

export default MobilePhone
